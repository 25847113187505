import React, { useEffect, useMemo, useRef } from 'react';

import { useInView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';

import { GalleryTheme } from '@bloom/codegen/models/GalleryTheme';
import { ImageLikeResponse } from '@bloom/codegen/models/ImageLikeResponse';

import disableImageRightClick from '@bloom/library/components/HOC/disableImageRightClick';
import withEmbedVideo from '@bloom/library/components/HOC/withEmbedVideo';
import ProgressiveImage from '@bloom/library/components/ProgressiveImage';

import { GalleryActionButtons } from './GalleryActionButtons';

import style from './SingleColumnImage.module.scss';

let SingleColumnImage: React.FC<IProps> = (props) => {
  const {
    albumId,
    galleryId,
    galleryImageLikes,
    galleryUrl,
    galleryWidth,
    image,
    isDownloadEnabled,
    isGalleryPublic,
    isGridInit,
    isLazyLoadEnabled = true,
    isLikeEnabled = true,
    onContextMenu,
    onDownloadImageClick,
    onImageClick,
  } = props;

  const wrapperRef = useRef();
  const { inView, ref: inViewRef } = useInView({ triggerOnce: true });

  const { height, width } = useMemo(() => {
    let w = galleryWidth > 0 ? Math.min(galleryWidth, image.width) : image.width;
    const ratio = image.width / image.height;
    const h = Math.min(782, w / ratio);

    if (h === 782) {
      // If image height was reduced then proportionally reduce the width
      w = h * ratio;
    }
    return { height: h, width: w };
  }, [galleryWidth, image.height, image.width]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.style.cssText = `width: ${width}px;height: ${height}px`;
    }
  }, [height, width]);

  function handleDownloadImageClick() {
    if (typeof onDownloadImageClick === 'function') {
      onDownloadImageClick(image.id);
    }
  }

  return (
    <div
      className={twMerge('group', style.wrapper)}
      data-testid="single-column-image"
      ref={(node) => {
        inViewRef(node);
        wrapperRef.current = node;
      }}
    >
      {isGridInit ? (
        <ProgressiveImage
          className={style.image}
          dimensions={{
            maxHeight: Math.round(width * 1.5),
            maxWidth: Math.round(height * 1.5),
          }}
          image={{
            ...image,
            source: isLazyLoadEnabled && !inView ? '' : image.source,
          }}
          onClick={onImageClick ? onImageClick.bind(null, image.id) : undefined}
          onContextMenu={onContextMenu}
        />
      ) : null}
      <footer
        className={twMerge(
          'group-hover:opacity-1 absolute bottom-0 left-0 z-10 flex w-full items-center justify-end gap-2 p-2 group-hover:opacity-100 md:opacity-0',
          style.footer
        )}
      >
        <GalleryActionButtons
          className={style.actionButtons}
          colorTheme={GalleryTheme.DARK}
          galleryImageLikes={galleryImageLikes}
          isCommentEnabled
          isDownloadEnabled={isDownloadEnabled}
          isLikeEnabled={isLikeEnabled}
          isPublic={isGalleryPublic}
          likeConfig={{ galleryId, imageId: image.id }}
          onDownloadClick={handleDownloadImageClick}
          shareLink={`${galleryUrl}?a=${albumId}&i=${image.id}`}
        />
      </footer>
    </div>
  );
};

SingleColumnImage.displayName = 'SingleColumnImage';

interface IProps {
  albumId: string;
  galleryId: string;
  galleryImageLikes: { [imageId: string]: ImageLikeResponse };
  galleryUrl: string;
  galleryWidth: number;
  image: unknown;
  isDownloadEnabled?: boolean;
  isGalleryPublic?: boolean;
  isGridInit: boolean;
  isLazyLoadEnabled?: boolean;
  isLikeEnabled?: boolean;
  onContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDownloadImageClick?: (imageId: string) => void;
  onImageClick: (imageId: string) => void;
}

SingleColumnImage = disableImageRightClick(SingleColumnImage);

export default withEmbedVideo(SingleColumnImage);
