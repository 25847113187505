/* eslint-disable */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import SquareImage from '../SquareImage';

import style from './SquareGrid.module.scss';

export default class SquareGrid extends Component {
  static propTypes = {
    albumId: PropTypes.string.isRequired,
    galleryId: PropTypes.string.isRequired,
    galleryImageLikes: PropTypes.object.isRequired,
    galleryUrl: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    isDownloadEnabled: PropTypes.bool,
    isLazyLoadEnabled: PropTypes.bool,
    isLikeEnabled: PropTypes.bool,
    onDownloadImageClick: PropTypes.func,
    onImageClick: PropTypes.func,
    onPlayEmbedVideo: PropTypes.func,
    screenWidth: PropTypes.number.isRequired,
    spacing: PropTypes.number,
    isGalleryPublic: PropTypes.bool,
  };

  static defaultProps = {
    isLazyLoadEnabled: true,
    onImageClick: () => {},
    onPlayEmbedVideo: () => {},
    spacing: 5, // keep the legacy gap by default
  };

  componentDidMount() {
    if (typeof this.props.onGridReady === 'function') {
      this.props.onGridReady();
    }
  }

  render() {
    const {
      albumId,
      galleryId,
      galleryImageLikes,
      galleryUrl,
      images,
      isDownloadEnabled,
      isLazyLoadEnabled,
      isLikeEnabled,
      onDownloadImageClick,
      onImageClick,
      onPlayEmbedVideo,
      screenWidth,
      spacing,
      isGalleryPublic,
    } = this.props;

    return (
      <div className={style.grid} style={{ gap: spacing, padding: spacing / 2 }}>
        {images.map((img) => (
          <SquareImage
            isGalleryPublic={isGalleryPublic}
            albumId={albumId}
            galleryId={galleryId}
            galleryImageLikes={galleryImageLikes}
            galleryUrl={galleryUrl}
            image={img}
            isDownloadEnabled={isDownloadEnabled}
            isFullSize={false}
            isLazyLoadEnabled={isLazyLoadEnabled}
            isLikeEnabled={isLikeEnabled}
            key={`${albumId}${img.id}`}
            onDownloadImageClick={onDownloadImageClick}
            onImageClick={onImageClick.bind(null, img.id)}
            onPlayEmbedVideo={onPlayEmbedVideo}
            screenWidth={screenWidth}
          />
        ))}
      </div>
    );
  }
}
