import React from 'react';

function PlayCircled({ width = 60, className = '', color = 'currentColor' }) {
  return (
    <svg width={width} height={width} className={className} viewBox="0 0 60 60">
      <path
        fill={color}
        d="M176,208a30,30,0,1,0-30-30A30,30,0,0,0,176,208Zm9-30-14,8V170Z"
        transform="translate(-146 -148)"
      />
    </svg>
  );
}

export default PlayCircled;
