import React from 'react';

import { useInView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';

import { GalleryTheme } from '@bloom/codegen/models/GalleryTheme';
import { ImageLikeResponse } from '@bloom/codegen/models/ImageLikeResponse';

import disableImageRightClick from '@bloom/library/components/HOC/disableImageRightClick';
import withEmbedVideo from '@bloom/library/components/HOC/withEmbedVideo';
import ProgressiveImage from '@bloom/library/components/ProgressiveImage';

import { GalleryActionButtons } from './GalleryActionButtons';

import style from './SquareImage.module.scss';

let SquareImage: React.FC<IProps> = (props) => {
  const {
    albumId,
    galleryId,
    galleryImageLikes,
    galleryUrl,
    image,
    isDownloadEnabled,
    isGalleryPublic,
    isLazyLoadEnabled = true,
    isLikeEnabled = true,
    onContextMenu,
    onDownloadImageClick,
    onImageClick,
  } = props;

  const { inView, ref: inViewRef } = useInView({ triggerOnce: true });

  function handleDownloadImageClick() {
    if (typeof onDownloadImageClick === 'function') {
      onDownloadImageClick(image.id);
    }
  }

  return (
    <div
      // Use albumId to trigger image loading,
      // even if previous album has the same images
      // as the current one, e.g. 'All photos' album.
      className={twMerge('group', style.wrapper)}
      data-testid="square-image"
      key={albumId + image.id}
      ref={inViewRef}
    >
      <div
        onClick={onImageClick.bind(null, image.id)}
        onContextMenu={onContextMenu}
        style={{ paddingBottom: '100%' }}
      >
        <ProgressiveImage
          className={style.image}
          dimensions={{
            maxHeight: 767,
            maxWidth: 767,
          }}
          image={{ ...image, source: isLazyLoadEnabled && !inView ? '' : image.source }}
        />
      </div>
      <footer
        className={twMerge(
          'group-hover:opacity-1 absolute bottom-0 left-0 z-10 flex w-full items-center justify-end gap-2 p-2 group-hover:opacity-100 md:opacity-0',
          style.footer
        )}
      >
        <GalleryActionButtons
          colorTheme={GalleryTheme.DARK}
          galleryImageLikes={galleryImageLikes}
          isCommentEnabled
          isDownloadEnabled={isDownloadEnabled}
          isLikeEnabled={isLikeEnabled}
          isPublic={isGalleryPublic}
          likeConfig={{ galleryId, imageId: image.id }}
          onDownloadClick={handleDownloadImageClick}
          shareLink={`${galleryUrl}?a=${albumId}&i=${image.id}`}
        />
      </footer>
    </div>
  );
};

SquareImage.displayName = 'SquareImage';

interface IProps {
  albumId: string;
  galleryId: string;
  galleryImageLikes: { [imageId: string]: ImageLikeResponse };
  galleryUrl: string;
  image: unknown;
  isDownloadEnabled: boolean;
  isGalleryPublic: boolean;
  isLazyLoadEnabled: boolean;
  isLikeEnabled?: boolean;
  onContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDownloadImageClick?: (imageId: string) => void;
  onImageClick: (imageId: string) => void;
}

SquareImage = disableImageRightClick(SquareImage);

export default withEmbedVideo(SquareImage);
