import React, { Component } from 'react';

export default (WrappedComp) => {
  class Tooltip extends Component {
    handleRightClick = (e) => {
      e.preventDefault();
    };

    render() {
      return <WrappedComp {...this.props} onContextMenu={this.handleRightClick} />;
    }
  }

  return Tooltip;
};
