'use client';
import React, { useCallback, useEffect, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import withEmbedVideo from '@bloom/library/components/HOC/withEmbedVideo';
import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';
import { useImageFallback } from '@bloom/library/components/hooks/useImageFallback';
import ProgressiveImage from '@bloom/library/components/ProgressiveImage';
import { getSizeSuffix } from '@bloom/library/utils/image';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './Slide.module.scss';

interface IProps {
  className?: string;
  getResizedImageSrc: () => void;
  images: Array<{ id: string; source: string }>;
  isCover?: boolean;
  onIndexChange?: (index: number) => void;
  photographerName?: string;
  selectedIndex: number;
  transitionType?: string;
}

const Slide: React.FC<IProps> = (props) => {
  const {
    className,
    images,
    isCover,
    onIndexChange,
    photographerName,
    selectedIndex,
    transitionType,
  } = props;

  const clientXRef = useRef(0);

  const containerRef = useRef<HTMLDivElement>();

  const activeTemplate = useActiveTemplate();

  function getImageSize(image) {
    if (image) {
      let width;
      let height;
      const { height: h1, width: w1 } = image;

      if (containerRef.current) {
        const { height: h2, width: w2 } = containerRef.current.getBoundingClientRect();
        if (h1 < h2 && w1 < w2) {
          width = w1;
          height = h1;
        } else if (h1 / h2 < w1 / w2) {
          width = w2;
          height = (h1 * w2) / w1;
        } else if (w1 / w2 < h1 / h2) {
          width = (w1 * h2) / h1;
          height = h2;
        }
      }

      return { height, max: Math.max(height, width), width };
    }

    if (containerRef.current) {
      const { height, width } = containerRef.current.getBoundingClientRect();
      return { height, max: Math.max(height, width), width };
    }
    return { height: 0, max: 0, width: 0 };
  }

  // Preload side images
  useImageFallback(
    images[selectedIndex - 1]?.source || '',
    getSizeSuffix(getImageSize(images[selectedIndex - 1]).max)
  );
  // Preload side images
  useImageFallback(
    images[selectedIndex]?.source || '',
    getSizeSuffix(getImageSize(images[selectedIndex + 1]).max)
  );

  const handleKeyDown = useCallback(
    (e) => {
      const keyListeners = {
        ArrowLeft: () => {
          if (typeof onIndexChange === 'function') {
            onIndexChange(selectedIndex - 1);
          }
        },
        ArrowRight: () => {
          if (typeof onIndexChange === 'function') {
            onIndexChange(selectedIndex + 1);
          }
        },
      };

      Object.keys(keyListeners).forEach((key) => {
        if (key === e.key) {
          keyListeners[key]();
          e.stopPropagation();
          e.preventDefault();
        }
      });
    },
    [onIndexChange, selectedIndex]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  function handleTouchStart(e) {
    // don't track, if more than a single touch
    if (e.touches && e.touches.length > 1) {
      return;
    }
    clientXRef.current = e.touches[0].clientX;
  }

  function handleTouchEnd(e) {
    const diff = clientXRef.current - e.changedTouches[0].clientX;
    if (Math.abs(diff) > 50) {
      onIndexChange(selectedIndex + Math.sign(diff));
    }
  }

  function renderFadeInOutLayout() {
    let itemsInShowcase = [];
    const selectedImage = images[selectedIndex];

    if (selectedIndex === 0 || selectedIndex === images.length - 1) {
      itemsInShowcase = images.slice(0, 2);
      if (selectedIndex === 0) {
        itemsInShowcase.unshift(images[images.length - 1]);
      } else {
        itemsInShowcase.push(images[images.length - 1]);
      }
    } else {
      itemsInShowcase = images.slice(selectedIndex - 1, selectedIndex + 2);
    }

    return itemsInShowcase.map((img, index) => (
      <ProgressiveImage
        // if images property has the only one image then itemsInShowcase will
        // have 2 same images with same ids which make key not unique and bleed
        // console.log with error "Encountered two children with the same key"
        className={twMerge(style.absolute, 'h-full w-full')}
        image={img}
        isCover={isCover}
        isProgressive={false}
        key={images.length === 1 ? index : img.id}
        style={{
          opacity: selectedImage.id === img.id ? 1 : 0,
          transition: 'opacity 400ms',
        }}
        timeout={400}
      >
        {photographerName && (
          <div className={style.credentials}>
            <span className={style.staticText}>All Photos</span>
            <span
              className={style.photographerName}
              dangerouslySetInnerHTML={{ __html: escapeHTML(`by ${photographerName}`) }}
            />
          </div>
        )}
      </ProgressiveImage>
    ));
  }

  function renderGeneralLayout() {
    const selectedImage = images[selectedIndex];
    const { height, width } = getImageSize(selectedImage);
    return (
      <ProgressiveImage image={selectedImage} isCover={isCover} style={{ height, width }}>
        {photographerName && (
          <div className={style.credentials}>
            <span className={style.staticText}>All Photos</span>
            <span
              className={style.photographerName}
              dangerouslySetInnerHTML={{ __html: escapeHTML(`by ${photographerName}`) }}
            />
          </div>
        )}
      </ProgressiveImage>
    );
  }

  function renderTemplate() {
    switch (transitionType) {
      case 'fade':
        return renderFadeInOutLayout();
      case 'general':
      default:
        return renderGeneralLayout();
    }
  }

  const selectedImage = images[selectedIndex];
  if (!selectedImage) {
    return null;
  }

  return (
    <div
      className={twMerge(
        style.container,
        className,
        !photographerName ? style.noCredentials : '',
        activeTemplate === 'nordic-air' ? style.nordicAirTemplate : ''
      )}
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      ref={containerRef}
    >
      {getImageSize ? renderTemplate() : null}
    </div>
  );
};

export default withEmbedVideo(Slide);
