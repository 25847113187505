import React, { useCallback, useMemo } from 'react';

import copy from 'copy-to-clipboard';
import { Form } from 'formik';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import { GalleryTheme } from '@bloom/codegen/models/GalleryTheme';
import { ImageLikeRequest } from '@bloom/codegen/models/ImageLikeRequest';
import { ImageLikeResponse } from '@bloom/codegen/models/ImageLikeResponse';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';
import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { IconButton } from '@bloom/ui/components/IconButton';
import { FacebookIcon } from '@bloom/ui/components/Icons/Facebook';
import { LikeFilledIcon } from '@bloom/ui/components/Icons/LikeFilled';
import { PinterestIcon } from '@bloom/ui/components/Icons/Pinterest';
import { TwitterIcon } from '@bloom/ui/components/Icons/Twitter';
import { Input } from '@bloom/ui/components/Input';
import { Modal } from '@bloom/ui/components/Modal';
import { OptionsButton } from '@bloom/ui/components/OptionsButton';
import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';
import { TextSizeEnum } from '@bloom/ui/components/Typography/Text';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { FormState } from '@bloom/library/components/Form/FormState';
import { useGalleryImageLikes } from '@bloom/library/components/hooks/useGalleryImageLikes';
import { CommentNewFilledIcon } from '@bloom/library/components/Icon/CommentNewFilled';
import { CopyV2Icon } from '@bloom/library/components/Icon/CopyV2';
import { DownloadIcon } from '@bloom/library/components/Icon/Download';
import { LikeIcon } from '@bloom/library/components/Icon/Like';
import { MessageIcon } from '@bloom/library/components/Icon/Message';
import { ShareIconV2 } from '@bloom/library/components/Icon/ShareV2';
import { HMega } from '@bloom/library/components/Typography/HMega';
import { setCookie } from '@bloom/library/utils/browser';

import { useGalleryPageContext } from './gallery-page-context';
import { GalleryCommentPopover } from './GalleryCommentPopover';

const validationSchema = yup.object({
  userEmail: yup.string().email().required(),
});

interface ILikeConfig {
  galleryId: string;
  imageId: string;
}

interface IProps {
  className?: string;
  colorTheme: GalleryTheme;
  galleryImageLikes?: { [imageId: string]: ImageLikeResponse };
  isCommentEnabled?: boolean;
  isDownloadEnabled: boolean;
  isLikeEnabled: boolean;
  isPublic?: boolean;
  likeConfig?: ILikeConfig;
  onDownloadClick?: () => void;
  shareLink: string;
}

interface IFormValues {
  userEmail: string;
}

export const LikeEmailConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (visitorEmail: string) => void;
}> = (props) => {
  const { isOpen, onClose, onSubmit } = props;

  const { onSetVisitorEmail } = useGalleryPageContext();

  const initialValues: IFormValues = useMemo(() => {
    return { userEmail: '' };
  }, []);

  function handleSubmit(values: IFormValues) {
    setCookie('user_email', values.userEmail, 'session');
    onSetVisitorEmail(values.userEmail);

    onSubmit(values.userEmail);

    onClose();
  }

  return (
    <Modal onClose={onClose} open={isOpen}>
      <FormState<IFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, handleChange, values }) => {
          return (
            <Form>
              <hgroup>
                <HMega className="text-center" size={TextSizeEnum['3XL']}>
                  Like it?
                </HMega>
                <p className="mt-3 text-balance text-center">
                  Save your favorite photos and revisit them anytime using your email address.
                </p>
              </hgroup>

              <div className="mt-12 flex gap-3">
                <Input
                  className="flex-1"
                  data-testid="email-input"
                  invalid={!!errors.userEmail}
                  name="userEmail"
                  onChange={handleChange}
                  placeholder="Your email"
                  type="email"
                  value={values.userEmail}
                />

                <PrimaryButton data-testid="submit-button" type="submit">
                  Next
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      </FormState>
    </Modal>
  );
};

export const GalleryActionButtons: React.FC<IProps> = ({
  className,
  colorTheme,
  galleryImageLikes = {},
  isCommentEnabled = false,
  isDownloadEnabled,
  isLikeEnabled,
  isPublic = true,
  likeConfig,
  onDownloadClick,
  shareLink,
}) => {
  const [searchParams] = useSearchParams();
  const [isEmailModalShown, { setFalse: closeEmailModal, setTrue: openEmailModal }] = useToggle();
  const [isCommentPopoverShown, { toggle: toggleCommentPopover }] = useToggle();

  const { visitorEmail } = useGalleryPageContext();

  const { showInfoMessage } = useInfoMessage();

  const { likeImage } = useGalleryImageLikes({
    accessToken: searchParams.get('pwd') || '',
    email: visitorEmail,
    galleryId: likeConfig?.galleryId || '',
  });

  const handleShareClick = useCallback(
    (value: string) => {
      if (value === 'copy') {
        const copied = copy(shareLink);
        if (copied) {
          showInfoMessage('Link copied to clipboard');
        }
      } else {
        let shareUrl = '';
        switch (value) {
          case 'facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`;
            break;
          case 'pinterest':
            shareUrl = `https://www.pinterest.com/pin/create/button/?url=${shareLink}`;
            break;
          case 'twitter':
            shareUrl = `https://twitter.com/intent/tweet?url=${shareLink}`;
            break;
          default:
            break;
        }

        const a = document.createElement('a');
        a.href = shareUrl;
        a.target = '_blank';
        // a.click();
        // click() callback doesn't trigger click event in FF w/o actual adding anchor in DOM tree
        // dispatch event instead
        a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
        a.remove();
      }
    },
    [shareLink, showInfoMessage]
  );

  const shareOptions = useMemo(() => {
    return [
      {
        icon: <FacebookIcon width={16} />,
        label: 'Share on Facebook',
        onClick: () => handleShareClick('facebook'),
      },
      {
        icon: <PinterestIcon width={16} />,
        label: 'Share on Pinterest',
        onClick: () => handleShareClick('pinterest'),
      },
      {
        icon: <TwitterIcon width={16} />,
        label: 'Share on Twitter',
        onClick: () => handleShareClick('twitter'),
      },
      {
        icon: <CopyV2Icon width={16} />,
        label: `Share URL link`,
        onClick: () => handleShareClick('copy'),
      },
    ];
  }, [handleShareClick]);

  function handleLikeClick(email?: string) {
    const computedEmail = email || visitorEmail;

    if (computedEmail) {
      const { imageId } = likeConfig as ILikeConfig;
      const like = galleryImageLikes[imageId];
      const likeRequest: ImageLikeRequest = {
        imageId,
        isLiked: !(like && like.isLiked),
        userEmail: computedEmail,
      };
      if (like?.comment) {
        likeRequest.comment = like.comment;
      }
      likeImage(likeRequest);
    } else {
      openEmailModal();
    }
  }

  function isImageLiked(imageId: string) {
    return Boolean(galleryImageLikes[imageId] && galleryImageLikes[imageId]?.isLiked);
  }

  function hasComment(imageId: string) {
    return Boolean(galleryImageLikes[imageId] && galleryImageLikes[imageId]?.comment);
  }

  function handleCommentClick() {
    if (visitorEmail) {
      toggleCommentPopover();
    } else {
      openEmailModal();
    }
  }

  return (
    <>
      <div
        className={twMerge(
          'flex items-center gap-2',
          isCommentPopoverShown ? 'opacity-100' : '',
          className
        )}
      >
        {isCommentEnabled && isLikeEnabled && likeConfig ? (
          <GalleryCommentPopover
            comment={galleryImageLikes[likeConfig?.imageId]?.comment || ''}
            galleryId={likeConfig?.galleryId}
            imageId={likeConfig?.imageId}
            isLiked={Boolean(galleryImageLikes[likeConfig?.imageId]?.isLiked)}
            isShown={isCommentPopoverShown}
            onClose={toggleCommentPopover}
            userEmail={visitorEmail}
          >
            <IconButton
              className={
                colorTheme === GalleryTheme.DARK
                  ? 'text-white hover:text-white'
                  : 'text-black hover:text-black'
              }
              data-testid="comment-button"
              onClick={handleCommentClick}
            >
              {hasComment(likeConfig?.imageId) ? <CommentNewFilledIcon /> : <MessageIcon />}
            </IconButton>
          </GalleryCommentPopover>
        ) : null}
        {isLikeEnabled && likeConfig ? (
          <IconButton
            aria-selected={isImageLiked(likeConfig.imageId)}
            className={
              colorTheme === GalleryTheme.DARK
                ? 'text-white hover:text-white'
                : 'text-black hover:text-black'
            }
            data-testid="like-button"
            onClick={() => handleLikeClick()}
          >
            {isImageLiked(likeConfig.imageId) ? <LikeFilledIcon /> : <LikeIcon />}
          </IconButton>
        ) : null}

        {isDownloadEnabled ? (
          <IconButton
            className={
              colorTheme === GalleryTheme.DARK
                ? 'text-white hover:text-white'
                : 'text-black hover:text-black'
            }
            data-testid="download-button"
            onClick={onDownloadClick}
          >
            <DownloadIcon />
          </IconButton>
        ) : null}

        {isPublic ? (
          <OptionsButton
            data-testid="share-options"
            menuItems={shareOptions}
            triggerIcon={ShareIconV2}
            triggerProps={{
              className:
                colorTheme === GalleryTheme.DARK
                  ? 'text-white hover:text-white'
                  : 'text-black hover:text-black',
            }}
          />
        ) : null}
      </div>

      <LikeEmailConfirmationModal
        isOpen={isEmailModalShown}
        onClose={closeEmailModal}
        onSubmit={handleLikeClick}
      />
    </>
  );
};
