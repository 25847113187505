/* eslint-disable */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ColumnImage from '../ColumnImage';

import style from './ColumnGrid.module.scss';

export default class ColumnGrid extends Component {
  static propTypes = {
    albumId: PropTypes.string.isRequired,
    galleryId: PropTypes.string.isRequired,
    galleryImageLikes: PropTypes.object.isRequired,
    galleryUrl: PropTypes.string.isRequired,
    galleryWidth: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    isDownloadEnabled: PropTypes.bool,
    isLazyLoadEnabled: PropTypes.bool,
    isLikeEnabled: PropTypes.bool,
    onDownloadImageClick: PropTypes.func,
    onImageClick: PropTypes.func,
    onPlayEmbedVideo: PropTypes.func,
    screenWidth: PropTypes.number.isRequired,
    spacing: PropTypes.number,
    isGalleryPublic: PropTypes.bool,
  };

  static defaultProps = {
    isLazyLoadEnabled: true,
    onImageClick: () => {},
    onPlayEmbedVideo: () => {},
    spacing: 5,
  };

  constructor(props) {
    super(props);

    this.state = {
      // Use that flag to render images after DOM dimensions are specified
      // to aboid loading images for entire gallery
      isGridInit: false,
    };
  }

  componentDidMount() {
    this.setupGrid();
  }

  componentDidUpdate(prevProps) {
    const { images, galleryWidth, albumId, spacing } = this.props;
    const { isGridInit } = this.state;

    if (
      images.length !== prevProps.images.length ||
      galleryWidth !== prevProps.galleryWidth ||
      prevProps.spacing !== spacing
    ) {
      this.setupGrid();
    }
    if (albumId !== prevProps.albumId && isGridInit) {
      this.setState({ isGridInit: false }, this.setupGrid); // eslint-disable-line react/no-did-update-set-state
    }
  }

  setupGrid() {
    const { screenWidth, galleryWidth } = this.props;
    const [firstImage] = this.props.images;
    if (!firstImage) {
      this.galleryNode.style.height = 0;
      return;
    }

    const { spacing } = this.props;
    let columnsNum = 3;
    if (screenWidth < 768) {
      columnsNum = 1;
    } else if (screenWidth < 1024) {
      columnsNum = 2;
    }

    const gutter = columnsNum > 1 ? spacing * (columnsNum - 1) : 0;
    const columnWidth = (galleryWidth - gutter) / columnsNum;
    const columnsHeights = Array(columnsNum).fill(0);
    // forEach() doesn't work in IE for NodeList and HTMLCollection
    // convert it to array
    const childNodes = Array.from(this.galleryNode.childNodes);
    childNodes.forEach((node, index) => {
      const image = this.props.images[index];
      const ratio = image.width / image.height;
      // Set image width before accessing its height.
      const imgHeight = columnWidth / ratio;
      node.style.width = `${columnWidth}px`;
      node.style.height = `${columnWidth / ratio}px`;

      const minHeight = Math.min(...columnsHeights);
      const columnIndex = columnsHeights.findIndex((height) => height === minHeight);

      // Set image position.
      node.style.left = `${columnIndex * (columnWidth + spacing)}px`;
      node.style.top = `${columnsHeights[columnIndex]}px`;

      node.style.pointerEvents = 'all';

      if (image.videoSource) {
        node.style.position = 'absolute';
      }

      columnsHeights[columnIndex] += imgHeight + spacing;
    });

    const maxColumnHeight = Math.ceil(Math.max(...columnsHeights));
    this.galleryNode.style.height = `${maxColumnHeight - 5}px`;

    if (this.props.images.length > 0 && !this.state.isGridInit) {
      if (typeof this.props.onGridReady === 'function') {
        this.props.onGridReady();
      }
      setTimeout(() => this.setState({ isGridInit: true }));
    }
  }

  render() {
    const {
      albumId,
      galleryId,
      galleryImageLikes,
      galleryUrl,
      images,
      isDownloadEnabled,
      isLazyLoadEnabled,
      isLikeEnabled,
      onDownloadImageClick,
      onImageClick,
      onPlayEmbedVideo,
      screenWidth,
      isGalleryPublic,
    } = this.props;
    const { isGridInit } = this.state;

    return (
      <div
        className={style.grid}
        ref={(node) => {
          this.galleryNode = node;
        }}
      >
        {images.map((img) => (
          <ColumnImage
            isGalleryPublic={isGalleryPublic}
            albumId={albumId}
            galleryId={galleryId}
            galleryImageLikes={galleryImageLikes}
            galleryUrl={galleryUrl}
            image={img}
            isFullSize={false}
            isGridInit={isGridInit}
            isDownloadEnabled={isDownloadEnabled}
            isLazyLoadEnabled={isLazyLoadEnabled}
            isLikeEnabled={isLikeEnabled}
            key={`${albumId}${img.id}`}
            onDownloadImageClick={onDownloadImageClick}
            onImageClick={onImageClick}
            onPlayEmbedVideo={onPlayEmbedVideo}
            screenWidth={screenWidth}
            spacing={5}
          />
        ))}
      </div>
    );
  }
}
