import { useMemo } from 'react';

import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ImageLikeRequest } from '@bloom/codegen/models/ImageLikeRequest';
import { ImageLikeResponse } from '@bloom/codegen/models/ImageLikeResponse';

import { emptyObject } from '@bloom/ui/utils/empty-value';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';

import { SharedQueryKeyEnum } from './interface';

interface IProps {
  accessToken: string;
  email: string;
  galleryId: string;
}

type IStateData = { [key: string]: ImageLikeResponse };

export function useGalleryImageLikes(
  { accessToken, email, galleryId }: IProps,
  queryOptions?: Partial<UseQueryOptions<IStateData>>
) {
  const client = useQueryClient();

  const { get, post } = useFetch();
  const { showErrorMessageFromResponse } = useInfoMessage();

  async function fetchImageLikesByEmail(): Promise<IStateData> {
    const response = await get<{ imageLikes: Array<ImageLikeResponse> }>(
      `/api/galleries/${galleryId}/likes?filters=userEmail=${encodeURIComponent(email)}${
        accessToken ? `&accessToken=${accessToken}` : ''
      }`
    );

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data.imageLikes.reduce(
        (acc: IStateData, like) => ({ ...acc, [like.imageId]: like }),
        {}
      );
    }

    return emptyObject;
  }

  const { data, isFetching } = useQuery({
    enabled: !!galleryId && !!email,
    queryFn: fetchImageLikesByEmail,
    queryKey: [SharedQueryKeyEnum.GALLERY_IMAGE_LIKES_LIST, galleryId, email, accessToken],
    ...queryOptions,
  });

  async function likeImage(request: ImageLikeRequest) {
    const { imageId, ...restRequest } = request;
    return await post<{ imageLike: ImageLikeResponse }, ImageLikeRequest>(
      `/api/galleries/${galleryId}/likes/${imageId}${
        accessToken ? `?accessToken=${accessToken}` : ''
      }`,
      restRequest
    );
  }

  const { mutateAsync: likeMutation, isPending: isLiking } = useMutation({
    mutationFn: likeImage,
    onSuccess: (response, variables) => {
      if (response.status === AsyncStatusEnum.SUCCESS) {
        client.setQueryData<IStateData>(
          [
            SharedQueryKeyEnum.GALLERY_IMAGE_LIKES_LIST,
            galleryId,
            email || variables.userEmail,
            accessToken,
          ],
          (oldData = emptyObject) => {
            return { ...oldData, [response.data.imageLike.imageId]: response.data.imageLike };
          }
        );
      }
      if (response.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(response);
      }
    },
  });

  return useMemo(() => {
    return { data, isFetching, isLiking, likeImage: likeMutation };
  }, [data, isFetching, isLiking, likeMutation]);
}
