import React from 'react';

import { useInView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';

import { GalleryTheme } from '@bloom/codegen/models/GalleryTheme';
import { ImageLikeResponse } from '@bloom/codegen/models/ImageLikeResponse';

import disableImageRightClick from '@bloom/library/components/HOC/disableImageRightClick';
import withEmbedVideo from '@bloom/library/components/HOC/withEmbedVideo';
import ProgressiveImage from '@bloom/library/components/ProgressiveImage';

import { GalleryActionButtons } from './GalleryActionButtons';

import style from './RowImage.module.scss';

let RowImage: React.FC<IProps> = (props) => {
  const {
    albumId,
    galleryId,
    galleryImageLikes,
    galleryUrl,
    image,
    isDownloadEnabled,
    isGalleryPublic,
    isGridInit,
    isLastImage,
    isLazyLoadEnabled = true,
    isLikeEnabled = true,
    onContextMenu,
    onDownloadImageClick,
    onImageClick,
  } = props;

  const { inView, ref: inViewRef } = useInView({ triggerOnce: true });

  function handleDownloadImageClick() {
    if (typeof onDownloadImageClick === 'function') {
      onDownloadImageClick(image.id);
    }
  }

  // The goal in minHeight & maxHeight is to have a big enough ratio
  // between flexBasis and maxWidth so image can stretch proportionally.
  const ratio = image.width / image.height;

  let dimensions;
  // The last image may take the whole width of the grid,
  // so its resolution should be high. Use default resolution
  // of ProgressiveImage which is 1920x1080.
  // TODO: exclude false positives
  if (!isLastImage) {
    dimensions = {
      maxHeight: 767,
      maxWidth: 767,
    };
  }

  return (
    <div className={twMerge('group', style.wrapper)} data-testid="row-image" ref={inViewRef}>
      <div
        onClick={onImageClick.bind(null, image.id)}
        onContextMenu={onContextMenu}
        style={{ paddingBottom: `${100 / ratio}%` }}
      >
        {isGridInit ? (
          <ProgressiveImage
            className={style.image}
            dimensions={dimensions}
            image={{ ...image, source: isLazyLoadEnabled && !inView ? '' : image.source }}
          />
        ) : null}
      </div>

      <footer
        className={twMerge(
          'absolute bottom-0 left-0 z-10 flex w-full items-center justify-end gap-2 p-2 group-hover:opacity-100 md:opacity-0',
          style.footer
        )}
      >
        <GalleryActionButtons
          colorTheme={GalleryTheme.DARK}
          galleryImageLikes={galleryImageLikes}
          isCommentEnabled
          isDownloadEnabled={isDownloadEnabled}
          isLikeEnabled={isLikeEnabled}
          isPublic={isGalleryPublic}
          likeConfig={{ galleryId, imageId: image.id }}
          onDownloadClick={handleDownloadImageClick}
          shareLink={`${galleryUrl}?a=${albumId}&i=${image.id}`}
        />
      </footer>
    </div>
  );
};

interface IProps {
  albumId: string;
  galleryId: string;
  galleryImageLikes: { [imageId: string]: ImageLikeResponse };
  galleryUrl: string;
  image: unknown;
  isDownloadEnabled?: boolean;
  isGalleryPublic?: boolean;
  isGridInit: boolean;
  isLastImage?: boolean;
  isLazyLoadEnabled?: boolean;
  isLikeEnabled?: boolean;
  onContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
  onDownloadImageClick?: (imageId: string) => void;
  onImageClick: (imageId: string) => void;
}

RowImage = disableImageRightClick(RowImage);

export default withEmbedVideo(RowImage);
