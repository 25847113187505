import React from 'react';

import { IIconProps } from './types';

export const CommentNewFilledIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => (
  <svg
    className={className}
    fill="none"
    height={width}
    viewBox="0 0 20 20"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.39886 2.39898C2.8677 1.93014 3.50358 1.66675 4.16663 1.66675H15.8333C16.4963 1.66675 17.1322 1.93014 17.6011 2.39898C18.0699 2.86782 18.3333 3.50371 18.3333 4.16675V12.5001C18.3333 13.1631 18.0699 13.799 17.6011 14.2678C17.1322 14.7367 16.4963 15.0001 15.8333 15.0001H6.17847L3.08922 18.0893C2.85088 18.3277 2.49245 18.399 2.18106 18.27C1.86966 18.141 1.66663 17.8371 1.66663 17.5001V4.16675C1.66663 3.50371 1.93002 2.86782 2.39886 2.39898Z"
      fill={color}
    />
  </svg>
);
