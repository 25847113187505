import { flushSync } from 'react-dom';
import { twMerge } from 'tailwind-merge';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';
import { doNothing, emptyArray, emptyObject } from '@bloom/ui/utils/empty-value';

import { SLIDESHOW } from '@bloom/library/components/Gallery/constants';
import PlayIcon from '@bloom/library/components/Icon/PlayCircled';

const withEmbedVideoHOC = (WrappedComp) => {
  function EmbedVideo(props) {
    const {
      gridType = '',
      images = emptyArray,
      onPlayEmbedVideo = doNothing,
      selectedIndex = 0,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    let { image = emptyObject } = props;

    if (gridType === SLIDESHOW && images.length > 0) {
      image = images[selectedIndex];
    }

    function handleClick() {
      const currentImageId = searchParams.get('i');
      flushSync(() => {
        if (currentImageId !== image.id) {
          searchParams.set('i', image.id);
          setSearchParams(searchParams);
        }
      });

      onPlayEmbedVideo(image.id);
    }

    return image?.videoSource ? (
      <div className={twMerge('relative', gridType === SLIDESHOW ? 'h-full w-full' : '')}>
        <WrappedComp {...props} />

        <button
          className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 cursor-pointer text-white"
          onClick={handleClick}
          type="button"
        >
          <PlayIcon width={60} />
        </button>
      </div>
    ) : (
      <WrappedComp {...props} />
    );
  }

  return EmbedVideo;
};

export default withEmbedVideoHOC;
