'use client';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { doNothing } from '@bloom/ui/utils/empty-value';

import { useMe } from '@bloom/library/components/hooks/useMe';
import { getCookie } from '@bloom/library/utils/browser';

interface IGalleryPageContext {
  onSetVisitorEmail: (email: string) => void;
  visitorEmail: string;
}

export const initialState = {
  onSetVisitorEmail: doNothing,
  visitorEmail: '',
};

const GalleryPageContext = createContext<IGalleryPageContext>(initialState);

const GalleryPageProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  const [visitorEmail, setVisitorEmail] = useState('');

  const { me } = useMe();

  const userEmail = useMemo(() => {
    if (me?.email) {
      return me.email;
    }

    if (typeof window !== 'undefined') {
      return getCookie('user_email') || '';
    }

    return '';
  }, [me?.email]);

  useEffect(() => {
    setVisitorEmail(userEmail);
  }, [userEmail]);

  const value = useMemo(
    () => ({ onSetVisitorEmail: setVisitorEmail, visitorEmail }),
    [visitorEmail]
  );

  return <GalleryPageContext.Provider value={value}>{children}</GalleryPageContext.Provider>;
};

function useGalleryPageContext() {
  const context = useContext(GalleryPageContext);

  if (context === undefined) {
    throw new Error('useGaleryPage must be used within a GalleryPageProvider');
  }

  return context;
}

export { GalleryPageProvider, useGalleryPageContext };
