__turbopack_context__.v({
  "absolute": "Slide-module-scss-module__j50lka__absolute",
  "container": "Slide-module-scss-module__j50lka__container",
  "credentials": "Slide-module-scss-module__j50lka__credentials",
  "next": "Slide-module-scss-module__j50lka__next",
  "noCredentials": "Slide-module-scss-module__j50lka__noCredentials",
  "nordicAirTemplate": "Slide-module-scss-module__j50lka__nordicAirTemplate",
  "photographerName": "Slide-module-scss-module__j50lka__photographerName",
  "prev": "Slide-module-scss-module__j50lka__prev",
  "staticText": "Slide-module-scss-module__j50lka__staticText",
});
