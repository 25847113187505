/* eslint-disable */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import SingleColumnImage from '../SingleColumnImage';

import style from './SingleColumnGrid.module.scss';

export default class SingleColumnGrid extends Component {
  static propTypes = {
    albumId: PropTypes.string.isRequired,
    galleryId: PropTypes.string.isRequired,
    galleryImageLikes: PropTypes.object.isRequired,
    galleryUrl: PropTypes.string.isRequired,
    galleryWidth: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    isDownloadEnabled: PropTypes.bool,
    isLazyLoadEnabled: PropTypes.bool,
    isLikeEnabled: PropTypes.bool,
    onDownloadImageClick: PropTypes.func,
    onImageClick: PropTypes.func.isRequired,
    onPlayEmbedVideo: PropTypes.func,
    screenWidth: PropTypes.number.isRequired,
    spacing: PropTypes.number,
    isGalleryPublic: PropTypes.bool,
  };

  static defaultProps = {
    isLazyLoadEnabled: true,
    onPlayEmbedVideo: () => {},
    spacing: 5, // keep the legacy gap by default
  };

  constructor(props) {
    super(props);

    this.state = {
      isGridInit: false,
    };
  }

  componentDidMount() {
    this.setState({ isGridInit: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  componentDidUpdate(prevProps) {
    const { albumId } = this.props;
    if (albumId !== prevProps.albumId && this.state.isGridInit) {
      this.setState({ isGridInit: false });
    }
    if (albumId === prevProps.albumId && !this.state.isGridInit) {
      if (typeof this.props.onGridReady === 'function') {
        this.props.onGridReady();
      }
      this.setState({ isGridInit: true });
    }
  }

  render() {
    const {
      albumId,
      galleryId,
      galleryImageLikes,
      galleryUrl,
      galleryWidth,
      images,
      isDownloadEnabled,
      isLazyLoadEnabled,
      isLikeEnabled,
      onDownloadImageClick,
      onImageClick,
      onPlayEmbedVideo,
      screenWidth,
      spacing,
      isGalleryPublic,
    } = this.props;
    const { isGridInit } = this.state;

    return (
      <div
        ref={(node) => {
          this.gridNode = node;
        }}
        className={style.grid}
        style={{ gap: spacing, padding: `${spacing / 2}px 0` }}
      >
        {images.map((img) => (
          <SingleColumnImage
            isGalleryPublic={isGalleryPublic}
            albumId={albumId}
            galleryId={galleryId}
            galleryImageLikes={galleryImageLikes}
            galleryUrl={galleryUrl}
            galleryWidth={galleryWidth}
            image={img}
            isDownloadEnabled={isDownloadEnabled}
            isGridInit={isGridInit}
            isLazyLoadEnabled={isLazyLoadEnabled}
            isLikeEnabled={isLikeEnabled}
            key={`${albumId}${img.id}`}
            onDownloadImageClick={onDownloadImageClick}
            onImageClick={onImageClick}
            onPlayEmbedVideo={onPlayEmbedVideo}
            screenWidth={screenWidth}
          />
        ))}
      </div>
    );
  }
}
