'use client';
/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SquareGrid from '@bloom/library/components/Gallery/Grids/SquareGrid';
import RowGrid from '@bloom/library/components/Gallery/Grids/RowGrid';
import ColumnGrid from '@bloom/library/components/Gallery/Grids/ColumnGrid';
import SingleColumnGrid from '@bloom/library/components/Gallery/Grids/SingleColumnGrid';
import SlideshowGrid from '@bloom/library/components/Gallery/Grids/SlideshowGrid';
import {
  ROWS,
  COLUMNS,
  SQUARES,
  SINGLE_COLUMN,
  SLIDESHOW,
} from '@bloom/library/components/Gallery/constants';
import { throttleHandler } from '@bloom/library/utils/browser';

export default class Grid extends Component {
  static propTypes = {
    galleryId: PropTypes.string.isRequired,
    galleryUrl: PropTypes.string.isRequired,
    gridType: PropTypes.string,
    images: PropTypes.array.isRequired,
    isDownloadEnabled: PropTypes.bool,
    isLazyLoadEnabled: PropTypes.bool,
    isLikeEnabled: PropTypes.bool,
    onGridReady: PropTypes.func,
    portfolio: PropTypes.object,
    spacing: PropTypes.number,
    windowObj: PropTypes.object,
    isGalleryPublic: PropTypes.bool,
  };

  static defaultProps = {
    gridType: '',
    isLazyLoadEnabled: true,
    portfolio: {},
    spacing: 5, // keep the legacy spacing by default.
  };

  constructor(props) {
    super(props);

    this.state = {
      screenWidth: 0,
      galleryWidth: 0,
    };

    this.handleResize = throttleHandler(this.handleResize);
  }

  componentDidMount() {
    const windowObj = this.props.windowObj || window;
    const gridType = this.props.gridType || this.props.portfolio?.gridType;

    if ([SQUARES, SINGLE_COLUMN, COLUMNS].indexOf(gridType) > -1) {
      windowObj.addEventListener('resize', this.handleResize);
    }

    this.setState({
      screenWidth: windowObj.innerWidth,
      galleryWidth: this.wrapper.getBoundingClientRect().width,
    });
  }

  componentWillUnmount() {
    const windowObj = this.props.windowObj || window;
    const gridType = this.props.gridType || this.props.portfolio?.gridType;
    if ([SQUARES, SINGLE_COLUMN, COLUMNS].indexOf(gridType) > -1) {
      windowObj.removeEventListener('resize', this.handleResize);
    }
  }

  handleResize = () => {
    const windowObj = this.props.windowObj || window;
    const galleryWidth = this.wrapper.getBoundingClientRect().width;
    if (galleryWidth !== this.state.galleryWidth) {
      this.setState({ galleryWidth, screenWidth: windowObj.innerWidth });
    }
  };

  renderGrid = () => {
    const gridProps = { ...this.props, ...this.state };

    switch (this.props.gridType || this.props.portfolio?.gridType) {
      case COLUMNS:
        return <ColumnGrid {...gridProps} />;
      case ROWS:
        return <RowGrid {...gridProps} />;
      case SQUARES:
        return <SquareGrid {...gridProps} />;
      case SINGLE_COLUMN:
        return <SingleColumnGrid {...gridProps} />;
      case SLIDESHOW:
        return <SlideshowGrid {...gridProps} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div
        ref={(node) => {
          this.wrapper = node;
        }}
      >
        {this.renderGrid()}
      </div>
    );
  }
}
