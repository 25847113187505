import React from 'react';

import { IIconProps } from './types';

const ShareIconV2: React.FC<IIconProps> = ({ width = 20, className = '', color = '#111' }) => (
  <svg width={width} height={width} className={className} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2.5a1.667 1.667 0 1 0 0 3.333A1.667 1.667 0 0 0 15 2.5Zm-2.368 4.012a3.333 3.333 0 1 0-.84-1.44L7.369 7.655a3.333 3.333 0 1 0 0 4.691l4.424 2.578a3.333 3.333 0 1 0 .842-1.438l-4.426-2.58a3.335 3.335 0 0 0 0-1.811l4.423-2.582Zm-6.215 2.61a.843.843 0 0 0 .046.08 1.66 1.66 0 0 1-.024 1.637A1.665 1.665 0 0 1 3.334 10a1.667 1.667 0 0 1 3.083-.879Zm7.096 5.958a.816.816 0 0 0 .102-.174 1.667 1.667 0 1 1-.102.174Z"
      fill={color}
    />
  </svg>
);
export { ShareIconV2 };
