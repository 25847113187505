/* eslint-disable */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import RowImage from '../RowImage';

import style from './RowGrid.module.scss';

export default class RowGrid extends Component {
  static propTypes = {
    albumId: PropTypes.string.isRequired,
    galleryId: PropTypes.string.isRequired,
    galleryImageLikes: PropTypes.object.isRequired,
    galleryUrl: PropTypes.string.isRequired,
    galleryWidth: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    isDownloadEnabled: PropTypes.bool,
    isLazyLoadEnabled: PropTypes.bool,
    isLikeEnabled: PropTypes.bool,
    onDownloadImageClick: PropTypes.func,
    onGridReady: PropTypes.func,
    onImageClick: PropTypes.func,
    onPlayEmbedVideo: PropTypes.func,
    screenWidth: PropTypes.number.isRequired,
    spacing: PropTypes.number,
    isGalleryPublic: PropTypes.bool,
  };

  static defaultProps = {
    onPlayEmbedVideo: () => {},
    onImageClick: () => {},
    isLazyLoadEnabled: true,
    spacing: 5,
  };

  constructor(props) {
    super(props);

    this.state = {
      isGridInit: false,
    };
  }

  componentDidMount() {
    if (this.props.screenWidth > 0) {
      this.setupGrid();
    }
  }

  componentDidUpdate(prevProps) {
    const { images, albumId, spacing } = this.props;
    const { isGridInit } = this.state;

    if (
      images.length !== prevProps.images.length ||
      (this.props.galleryWidth > 0 && prevProps.galleryWidth === 0) ||
      prevProps.spacing !== spacing
    ) {
      this.setupGrid();
    }
    if (albumId !== prevProps.albumId && isGridInit) {
      this.setState({ isGridInit: false }, this.setupGrid); // eslint-disable-line react/no-did-update-set-state
    }
  }

  setupGrid() {
    const { spacing, screenWidth } = this.props;
    // forEach() doesn't work in IE for NodeList and HTMLCollection
    // convert it to array
    const childNodes = Array.from(this.galleryNode.childNodes);
    childNodes.forEach((node, index) => {
      const image = this.props.images[index];
      const ratio = image.width / image.height;

      let cssString = '';

      const minHeight = screenWidth < 1100 ? 300 : 360;
      const maxHeight = screenWidth;

      if (screenWidth < 768) {
        cssString = `width: 100%;margin: ${spacing / 2}px 0;`;
      } else {
        cssString = `flex-grow: ${Math.max(ratio, 1)};flex-basis: ${minHeight * ratio}px;margin: ${
          spacing / 2
        }px;max-height: ${maxHeight}px;`;
      }
      node.style.cssText = cssString;
    });

    if (this.props.images.length > 0 && !this.state.isGridInit) {
      setTimeout(() => {
        if (typeof this.props.onGridReady === 'function') {
          this.props.onGridReady();
        }
        this.setState({ isGridInit: true });
      });
    }
  }

  render() {
    const {
      albumId,
      galleryId,
      galleryImageLikes,
      galleryUrl,
      images,
      isDownloadEnabled,
      isLazyLoadEnabled,
      isLikeEnabled,
      onDownloadImageClick,
      onImageClick,
      onPlayEmbedVideo,
      screenWidth,
      isGalleryPublic,
    } = this.props;

    const { isGridInit } = this.state;

    return (
      <div
        ref={(node) => {
          this.galleryNode = node;
        }}
        className={style.grid}
      >
        {images.map((img, index) => (
          <RowImage
            isGalleryPublic={isGalleryPublic}
            albumId={albumId}
            galleryId={galleryId}
            galleryImageLikes={galleryImageLikes}
            galleryUrl={galleryUrl}
            image={img}
            isDownloadEnabled={isDownloadEnabled}
            isFullSize={false}
            isGridInit={isGridInit}
            isLastImage={index === images.length - 1}
            isLazyLoadEnabled={isLazyLoadEnabled}
            isLikeEnabled={isLikeEnabled}
            key={`${albumId}${img.id}`}
            onDownloadImageClick={onDownloadImageClick}
            onImageClick={onImageClick}
            onPlayEmbedVideo={onPlayEmbedVideo}
            screenWidth={screenWidth}
            spacing={5}
          />
        ))}
      </div>
    );
  }
}
