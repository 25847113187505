import React, { useMemo } from 'react';

import * as yup from 'yup';

import { useSearchParams } from '@bloom/ui/components/hooks/useSearchParams';
import { IconButton } from '@bloom/ui/components/IconButton';
import { Popover, PopoverContent, PopoverTrigger } from '@bloom/ui/components/Popover';
import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';
import { Textarea } from '@bloom/ui/components/Textarea';

import { FormState } from '@bloom/library/components/Form/FormState';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useGalleryImageLikes } from '@bloom/library/components/hooks/useGalleryImageLikes';
import { TrashIcon } from '@bloom/library/components/Icon/Trash';

const validationSchema = yup.object({
  userEmail: yup.string(),
});

interface IFormValues {
  comment: string;
}

interface IProps {
  comment: string;
  galleryId: string;
  imageId: string;
  isLiked: boolean;
  isShown: boolean;
  onClose: () => void;
  userEmail: string;
}

export const GalleryCommentPopover: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  comment,
  galleryId,
  imageId,
  isLiked,
  isShown,
  onClose,
  userEmail,
}) => {
  const [searchParams] = useSearchParams();

  const initialValues: IFormValues = useMemo(() => {
    return {
      comment,
    };
  }, [comment]);

  const { likeImage, isLiking: isCommentBeingSaved } = useGalleryImageLikes(
    { accessToken: searchParams.get('pwd') || '', email: userEmail, galleryId },
    { enabled: false }
  );

  async function handleSubmit(values: IFormValues) {
    const res = await likeImage({ comment: values.comment, imageId, isLiked, userEmail });

    if (res.status === AsyncStatusEnum.SUCCESS) {
      onClose();
    }
  }

  function handleDeleteClick() {
    handleSubmit({ comment: '' });
  }

  function handleOpenChange(newOpen: boolean) {
    if (!newOpen) {
      onClose();
    }
  }

  return (
    <Popover onOpenChange={handleOpenChange} open={isShown} placement="bottom">
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="w-96 p-6">
        <FormState<IFormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleChange, submitForm, values }) => (
            <>
              <Textarea
                className="mb-6"
                data-testid="comment-textarea"
                name="comment"
                onChange={handleChange}
                placeholder="Type your comment here"
                textareaClassName="min-h-20"
                value={values.comment}
              />
              <div className="flex items-center justify-between gap-6">
                <div className="flex items-center gap-3">
                  <PrimaryButton
                    data-testid="submit-button"
                    loading={isCommentBeingSaved}
                    onClick={submitForm}
                  >
                    Save
                  </PrimaryButton>
                  <PrimaryButton data-testid="cancel-button" onClick={onClose} variant="secondary">
                    Cancel
                  </PrimaryButton>
                </div>
                {comment ? (
                  <IconButton
                    data-testid="delete-comment-button"
                    disabled={isCommentBeingSaved}
                    onClick={handleDeleteClick}
                  >
                    <TrashIcon />
                  </IconButton>
                ) : null}
              </div>
            </>
          )}
        </FormState>
      </PopoverContent>
    </Popover>
  );
};
