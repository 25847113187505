import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import Slide from '@bloom/library/components/Gallery/Slideshow/Slide';
import withTemplate from '@bloom/library/components/HOC/withTemplate';

import style from './SlideshowGrid.module.scss';

class SlideshowGrid extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    containerClassName: PropTypes.string,
    onLoadStateChange: PropTypes.func,
  };

  static defaultProps = {
    containerClassName: '',
    onLoadStateChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  componentDidMount() {
    // start slideshow after the 1st image is loaded
    // it improves user experiense because loading the 1st image
    // takes time and sometimes depends on internet speed slide visually changes
    // right after loaded image has been shown
    const { images } = this.props;
    if (images.length > 0) {
      const img = new Image();
      img.src = images[0].source;
      img.onload = () => {
        if (this._isMounted) {
          this.handleSlideshowInterval();
        }
      };
    }
    this._isMounted = true;

    if (typeof this.props.onGridReady === 'function') {
      this.props.onGridReady();
    }
  }

  componentWillUnmount() {
    if (this.autoSlideshowInterval !== undefined) {
      clearInterval(this.autoSlideshowInterval);
      this.autoSlideshowInterval = undefined;
    }
    this._isMounted = false;
  }

  handleSlideshowInterval = () => {
    const { images } = this.props;
    this.autoSlideshowInterval = setInterval(() => {
      let nextIndex = this.state.selectedIndex + 1;

      if (nextIndex > images.length - 1) {
        nextIndex = 0;
      } else if (nextIndex < 0) {
        nextIndex = images.length - 1;
      }
      const selectedIndex = Math.max(0, nextIndex);
      // check if the next image is loaded
      const nextImg = new Image();
      nextImg.src = images[nextIndex].source;
      if (nextImg.complete) {
        this.setState({ selectedIndex });
      } else {
        // wait until the next image will be loaded
        clearInterval(this.autoSlideshowInterval);
        nextImg.onload = () => {
          this.setState({ selectedIndex });
          this.handleSlideshowInterval();
        };
      }
    }, 3000);
  };

  render() {
    const { containerClassName, ...restProps } = this.props;
    const { selectedIndex } = this.state;

    if (this.props.images.length === 0) {
      return null;
    }

    return (
      <div className={twMerge(style.container, containerClassName)}>
        <Slide
          {...restProps}
          className={style.gridSlideshow}
          isArrowsShown={false}
          selectedIndex={selectedIndex}
          transitionType="fade"
        />
      </div>
    );
  }
}

export default withTemplate(SlideshowGrid);
