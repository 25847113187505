import React from 'react';

import { IIconProps } from './types';

export const LikeFilledIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => (
  <svg className={className} fill="none" height={width} viewBox="0 0 21 18" width={width}>
    <path
      clipRule="evenodd"
      d="M12.718 2.078a5.417 5.417 0 0 1 5.904 8.836l-7.367 7.367a.833.833 0 0 1-1.178 0L2.71 10.914a5.418 5.418 0 0 1 7.662-7.662l.294.295.294-.294a5.418 5.418 0 0 1 1.758-1.175Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
